.invitee-sheet-modal-content {
  @apply rounded-lg bg-stone-50 bottom-0 absolute left-1/2 p-6 overflow-y-auto w-[30rem];

  transform: translate(-50%, 100%);

  // box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
  //   rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  // bottom: 0%;
  // position: absolute;
  // left: 50%;
  // margin-right: -50%;
  // width: 30rem;
  // top: auto;
  // padding: 1.5rem;
  min-height: 30rem;
  max-height: 90vh;
  // height: 25rem;
  // overflow-y: auto;
  z-index: 100;

  @media screen and (max-width: 500px) {
    width: 100%;
    left: 0;
    transform: translate(0, 100%);
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  // align-items: start;

  // opacity: 0;

  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &.ReactModal__Content--after-open {
    // opacity: 1;
    transform: translate(-50%, 0);

    @media screen and (max-width: 500px) {
      transform: translate(0, 0);
    }
  }

  &.ReactModal__Content--before-close {
    transform: translate(-50%, 100%);

    @media screen and (max-width: 500px) {
      transform: translate(0, 100%);
    }
  }
}

.invitee-sheet-modal-overlay {
  position: fixed;
  inset: 0px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 99;

  &.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.75);
  }

  &.ReactModal__Overlay--before-close {
    background-color: rgba(0, 0, 0, 0);
  }
}

.button {
  display: flex;
  align-items: center;

  background: none;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;

  background-color: #fff;
  color: #000;

  font-size: 1em;

  > svg {
    margin-right: 0.5rem;
  }

  &:hover:not(:disabled) {
    background-color: darken(#ffffff, 10%);
  }

  &:disabled {
    opacity: 0.5;
  }

  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  &.success {
    @apply bg-primary;
    // background-color: $accent-color;
    color: #fff;

    &:hover:not(:disabled) {
      // background-color: darken(#5eb800, 10%);
      @apply bg-primary-darker;
    }
  }

  &.destructive {
    // background-color: #ea6e66;
    // color: #fff;
    @apply bg-red-400 text-white;

    &:hover:not(:disabled) {
      // background-color: darken(#ea6e66, 10%);
      @apply bg-red-500;
    }
  }
}
