@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&family=Grey+Qo&display=swap');

input[type=text] {
  @apply px-4 py-2 rounded bg-control focus:outline-primary-darker w-full mb-2
}

.invitee-edit-sheet {
  @apply w-full;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .row {
    display: flex;

    >* {
      margin-right: 0.5rem;
    }
  }

  >.header {
    display: flex;
    margin-bottom: 1rem;

    .title {
      flex-grow: 1;
      font-size: 1.5rem;
      align-items: center;
    }
  }

  .actions {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    width: 100%;

    >*:not(:last-child) {
      margin-right: 0.5rem;
    }

    >*:first-child {
      flex-grow: 1;
    }
  }

  .section-title {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: bold;

    &.no-margin-top {
      margin-top: 0;
    }
  }
}

.callout {
  @apply p-4 m-4 rounded text-center;
}
.callout.error {
@apply bg-red-100 border border-red-500 text-red-900;
}

.callout.success {
@apply bg-green-100 border border-green-500 text-green-900;
}
